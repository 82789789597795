import React from "react";
import type { NextPage } from "next";
import Layout from "../layout/layout";
import Image from "next/image";
import login from "../assets/images/login.jpg";
import styles from "../styles/Login.module.scss";
const Login: NextPage = () => {
  return (
    <Layout>
      <div className="container">
        <div className={styles.breadcrumb_main}>
          <div className="row">
            <div className="col-md-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Login
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <section className={styles.login}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <div className="row align-items-center">
                <div className="col-md-6 d_none_mobile">
                  <Image src={login} alt="" />
                </div>
                <div className="col-md-6">
                  <h2>Login to senco</h2>
                  <form>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        placeholder="Mobile No"
                        className="form-control"
                      />
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        placeholder="Password"
                        className="form-control"
                      />
                    </div>
                    <button aria-label="LOGIN button" role="button">
                      LOGIN
                    </button>
                    <div className="row g-0">
                      <div className="col-5">
                        <p>
                          <a href="#">Forgot Password</a>
                        </p>
                      </div>
                      <div className="col-7">
                        <p>
                          New to Senco <a href="#">Create an account</a>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Login;
